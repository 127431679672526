import Logo from "../Header/Logo/Logo"

function Footer() {
    return (
        <footer className="w-100 py-4 bg-white mt-auto">
            <div className="container py-4">
                <div className="row">
                    <div className="col-12 col-lg-4 col-md-6">
                        <h5 className="h1 text-center"><Logo /></h5>
                        <p className="small text-muted text-center">TTAC - Cung cấp các tin tức mới nhất về công nghệ, khoa học và đời sống.</p>
                        <p className="small text-muted mb-0 text-center">&copy; Copyrights. All rights reserved. <a className="tac-link" href="#">www.ttac.vn</a></p>
                    </div>
                    <div className="col-6 col-lg-2 col-md-3">
                        <h5 className='mb-1'>Trang</h5>
                        <ul className="list-unstyled">
                            <li><a className='tac-link tac-font-normal' href="#">Trang chủ</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Chúng tôi</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Dịch vụ</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Chính sách</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2 col-md-3">
                        <h5 className='mb-1'>Trang</h5>
                        <ul className="list-unstyled">
                            <li><a className='tac-link tac-font-normal' href="#">Trang chủ</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Chúng tôi</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Dịch vụ</a></li>
                            <li><a className='tac-link tac-font-normal' href="#">Chính sách</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 justify-content-center">
                        <p className="small text-muted">Đăng ký để nhận được thông báo về những tin tức mới nhất.</p>
                        <form action="#">
                            <div className="input-group mb-3">
                                <input className="form-control tac-input" type="text" placeholder="Địa chỉ email" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button className="tac-btn-fill tac-btn-primary" id="button-addon2" type="button"><i className="bi bi-send-fill me-2"></i>Nhận thông báo</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer