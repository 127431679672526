import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routePaths from './pages/routePaths';
import Layout from './components/Layout/Layout';
import CheckIsAuthorization from './pages/ProtectedRoute';
import LoadingPage from './components/UI/LoadingPage/LoadingPage';
import PostsImport from './pages/Post/PostsImport';
import PostCreateMultiple from './pages/Post/PostCreateMultiple';
const MyInfo = lazy(() => import('./pages/MyInfoDevice'));
const HomePage = lazy(() => import('./pages/Home/Home'));
const LoginPage = lazy(() => import('./pages/Login/Login'));
const RegisterPage = lazy(() => import('./pages/Register/Register'));
const ProfilePage = lazy(() => import('./pages/Profile/Profile'));
const PostDetailPage = lazy(() => import('./pages/Post/PostDetail'));
const PostListPage = lazy(() => import('./pages/Post/PostList'));
const EditProfilePage = lazy(() => import('./pages/Profile/EditProfile'));
const GalleryPage = lazy(() => import('./pages/Gallery/Gallery'));
const PostCreatePage = lazy(() => import('./pages/Post/PostCreate'));
const SearchResultPage = lazy(() => import('./pages/Post/PostSearch'));
const Topic = lazy(() => import('./pages/Topic/Topic'));
const Setting = lazy(() => import('./pages/Setting/Setting'));
const Discovery = lazy(() => import('./pages/Discovery/Discorvery'))


const FallbackComponent = () => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const minLoadingTime = 5000;
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, minLoadingTime);
    return () => clearTimeout(timer);
  }, []);

  return showLoading ? <LoadingPage /> : null;
};

const AppRouter = () => (
  <Router>
    <Suspense fallback={<FallbackComponent />}>
      <Routes>
        <Route path={routePaths.myDevice} element={
          <Layout
            headerProps={{ title: 'Thông tin thiết bị', hasSearchForm: false }}
            meta={{ title: 'Thông tin thiết bị', description: 'Chi tiết về thiết bị của bạn', keywords: 'thiết bị, thông tin' }}
          >
            <MyInfo />
          </Layout>
        } />
        <Route path={routePaths.login} element={
          <CheckIsAuthorization isAuthorization={false} element={
            <LoginPage />
          } />
        } />
        <Route path={routePaths.register} element={
          <CheckIsAuthorization isAuthorization={false} element={
            <RegisterPage />
          } />
        } />
        <Route path={routePaths.home} element={
          <Layout
            hasNavBot={true}
            headerProps={{ title: "Trang Chủ", hasSearchForm: true, notStickyTop: true }}
            meta={{ title: 'Trang Chủ', description: 'Trang chủ của website', keywords: 'home, trang chủ' }}
          >
            <HomePage />
          </Layout>
        } />
        <Route path={'/home'} element={
          <Layout
            headerProps={{ title: "Trang Chủ", hasSearchForm: true }}
            meta={{ title: 'Trang Chủ', description: 'Trang chủ của website', keywords: 'home, trang chủ' }}
          >
            <HomePage />
          </Layout>
        } />
        <Route path={routePaths.profile} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Thông tin cá nhân", hasSearchForm: false }}
              meta={{ title: 'Thông tin cá nhân', description: 'Chi tiết thông tin cá nhân', keywords: 'profile, thông tin cá nhân' }}
            >
              <ProfilePage />
            </Layout>
          } />
        } />
        <Route path={routePaths.postDetail} element={
          <PostDetailPage />
        } />
        <Route path={routePaths.gallery} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Thư viện ảnh", hasSearchForm: false }}
              meta={{ title: 'Thư viện ảnh', description: 'Thư viện ảnh cá nhân', keywords: 'thư viện, ảnh' }}
            >
              <GalleryPage />
            </Layout>
          } />
        } />
        <Route path={routePaths.editProfile} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Chỉnh sửa thông tin cá nhân", hasSearchForm: false }}
              meta={{ title: 'Chỉnh sửa thông tin cá nhân', description: 'Chỉnh sửa chi tiết thông tin cá nhân', keywords: 'chỉnh sửa, thông tin cá nhân' }}>
              <EditProfilePage />
            </Layout>
          } />
        } />
        <Route path={routePaths.postsList} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Danh sách bài viết", hasSearchForm: false }}
              meta={{ title: 'Danh sách bài viết', description: 'Danh sách các bài viết', keywords: 'bài viết, danh sách' }}
            >
              <PostListPage />
            </Layout>
          } />
        } />
        <Route path={routePaths.postCreate} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Tạo bài viết", hasSearchForm: false }}
              meta={{ title: 'Tạo bài viết mới', description: 'Tạo một bài viết mới', keywords: 'tạo bài viết, bài viết mới' }}
            >
              <PostCreatePage />
            </Layout>
          } />
        } />
        <Route path={routePaths.searchPost} element={
          <Layout
            hasNavBot={true}
            headerProps={{ title: "Kết quả tìm kiếm", notStickyTop: true, hasSearchForm: true }}
            meta={{ title: 'Kết quả tìm kiếm', description: 'Kết quả tìm kiếm bài viết', keywords: 'tìm kiếm, kết quả' }}
          >
            <SearchResultPage />
          </Layout>
        } />
        <Route path={routePaths.topic} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Quản lý chủ đề", hasSearchForm: false }}
              meta={{ title: 'Quản lý chủ đề', description: 'Quản lý các chủ đề', keywords: 'chủ đề, quản lý' }}
            >
              <Topic />
            </Layout>
          } />
        } />
        <Route path={routePaths.setting} element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Quản lý trang web", hasSearchForm: false }}
              meta={{ title: 'Quản lý trang web', description: 'Quản lý các cài đặt trang web', keywords: 'quản lý, trang web' }}
            >
              <Setting />
            </Layout>
          } />
        } />
        <Route path='/postsimport' element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Nhập bài viết hàng loạt", hasSearchForm: false }}
              meta={{ title: 'Quản lý trang web', description: 'Quản lý các cài đặt trang web', keywords: 'quản lý, trang web' }}
            >
              <PostsImport />
            </Layout>
          } />
        }>

        </Route>
        <Route path='/hotnews' element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Tìm kiếm bài viết", hasSearchForm: false }}
              meta={{ title: 'Quản lý trang web', description: 'Quản lý các cài đặt trang web', keywords: 'quản lý, trang web' }}>
              <PostCreateMultiple />
            </Layout>
          } />
        }>
        </Route>
        <Route path='/discovery' element={
          <CheckIsAuthorization isAuthorization={true} element={
            <Layout
              headerProps={{ title: "Khám phá", hasSearchForm: false }}
              meta={{ title: 'Khám phá', description: 'Khám phá các tin tức mới nhất với giao diện mới mẻ nhất', keywords: 'khám phá, tin tức' }}>
              <Discovery />
            </Layout>
          } />
        }>
        </Route>
      </Routes>
    </Suspense>
  </Router>
);

export default AppRouter;
