import axios from "axios";
import responseHandler from "../utils/responseHandler";
import { API_URL } from '../config';

export const getTopics = async (pageNumber, pageSize) => {
  try {
    const params = new URLSearchParams({ pageNumber, pageSize });
    return await responseHandler(
      axios.get(`${API_URL}/topics?${params.toString()}`)
    )
  }
  catch (err) {
    console.log(err)
  }
}
export const createTopic = async (userData) => {
  try {
    return await responseHandler(
      axios.post(`${API_URL}/topics/create-topic`, userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    )
  } catch {

  }

}
export default {
  getTopics,
  createTopic
}