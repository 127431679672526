// src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/tac_style.css';
import React from 'react';
import AppRouter from './AppRouter'
function App() {
  return (
    <AppRouter />
  );
}

export default App;
