import axios from "axios";
import { useState } from "react";

function PostCreateMultiple() {
    const apiKey = '28f76d9757884d358c4b46b029af656c';
    const url = `https://newsapi.org/v2/everything`;
    const [query, setQuery] = useState('');
    const [articles, setArticles] = useState([]);
    const onFetch = async () => {
        if (query.length === 0) return;
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        var priorDate = new Date(new Date().setDate(date.getDate() - 30));
        const beginDate = `${priorDate.getFullYear()}-${priorDate.getMonth() + 1} ${priorDate.getDate()}`
        try {
            var response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json, text/plain, */*'
                },
                params: {
                    q: query,
                    from: beginDate,
                    to: formattedDate,
                    sortBy: 'popularity',
                    apiKey: apiKey
                }
            });
            console.log(response);
            if (response.status === 200) {
                setArticles(response.data.articles);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row mt-3">
                <div className="input-group mb-3">
                    <input type="text" onChange={(e) => { setQuery(e.target.value); }} className="form-control" placeholder="Nhập nội dung cần tìm kiếm bài viết" />
                    <button onClick={onFetch} className="btn btn-outline-primary" type="button" id="button-addon2">Fetch</button>
                </div>
            </div>
            <div className="row mt-3 justify-content-center">
                {
                    articles.map((article, index) => {
                        if (article.author && article.urlToImage) {
                            return (
                                <div key={index} className="col-md-3 d-flex align-items-stretch mb-3">
                                    <div className="card" style={{ width: '100%' }}>
                                        <img style={{ height: '15rem', objectFit: 'cover' }} src={article.urlToImage || 'https://icon-library.com/images/no-picture-available-icon/no-picture-available-icon-20.jpg'} className="card-img-top" alt={article.title} />
                                        <div className="card-body">
                                            <h5 className="card-title">{article.author}</h5>
                                            <h5 className="card-title">{article.title}</h5>
                                            <p className="card-text">{article.description}</p>
                                            <a href={article.url} className="btn btn-primary">Đi tới bài viết</a>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })
                }
            </div>
        </div>
    );
}

export default PostCreateMultiple;
