// src/components/Loading.js
import React from 'react';
import './LoadingPage.css'
const LoadingPage = () => {
  return (
    <div className='tac-loading-container'>
      <div className="loading">
        <div className="arc"></div>
        <div className="arc"></div>
        <div className="arc"></div>
      </div>
    </div>
  );
};

export default LoadingPage;
