import responseHandler from "../utils/responseHandler";
import axios from "axios";
import authService from "./authService";
import { API_URL } from '../config';

export const getCurrentUser = async () => {
    const token = authService.getCurrentTokenStr();
    if (token) {
        try {
            return await responseHandler(
                axios.get(`${API_URL}/users/current-user`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
            )
        } catch (error) {
            console.log(error)
        }
    }
}
export const setUser = async () => {
    const userInformation = await getCurrentUser();
    localStorage.setItem('userInformation', JSON.stringify(userInformation.response.result));
}
export const getCurrentUserFromLocalStorge = () => {
    const userJson = localStorage.getItem('userInformation');
    if (!userJson) return null
    return JSON.parse(userJson)
}
export const getUserById = async (userId) => {
    try {
        return await responseHandler(
            axios.get(`${API_URL}/users/${userId}`)
        )
    } catch (error) {
        console.log(error)
    }
}
export default {
    getCurrentUser,
    getCurrentUserFromLocalStorge,
    setUser,
    getUserById
}