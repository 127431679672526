import { useNavigate } from 'react-router-dom';
import '../SearchForm/SearchFormStyle.css';
import React, { useRef, useState, useEffect } from 'react';
import postService from '../../../services/postService';

export function SearchForm() {
    const [isFocused, setIsFocused] = useState(false);
    const [inputWidth, setInputWidth] = useState(0);
    const [input, setInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const searchResultRef = useRef(null);

    const updateInputWidth = () => {
        if (inputRef.current) {
            setInputWidth(inputRef.current.offsetWidth);
        }
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (input.trim()) {
            navigate(`/search?query=${encodeURIComponent(input)}`);
        }
    };

    const fetchRandomPosts = async () => {
        setLoading(true);
        const response = await postService.getPosts({ pageNumber: 1, pageSize: 5 });
        if (response.isSuccess) {
            setSearchResults(response.response.result);
        } else {
            setSearchResults([]);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!inputRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            updateInputWidth();
        });
        resizeObserver.observe(inputRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (input.trim()) {
                setLoading(true);
                const response = await postService.getPosts({ Keyword: input, pageNumber: 1, pageSize: 10 });
                if (response.isSuccess) {
                    setSearchResults(response.response.result);
                } else {
                    setSearchResults([]);
                }
                setLoading(false);
            } else {
                fetchRandomPosts();
            }
        };

        fetchSearchResults();
    }, [input]);

    const handleArticleClick = (articleId) => {
        navigate(`/post/${articleId}`);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    return (
        <form className="d-flex align-items-center" onSubmit={handleSearchSubmit}>
            <div className="w-100" style={{ textAlign: 'center' }}>
                <input
                    ref={inputRef}
                    id="searchForm"
                    className="tac-search-form"
                    type="search"
                    autoComplete="off"
                    placeholder="Tìm kiếm"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onFocus={() => {
                        setIsFocused(true);
                        if (!input.trim()) {
                            fetchRandomPosts();
                        }
                    }}
                    onBlur={handleInputBlur}
                />
                {loading ? (
                    ''
                ) : (
                    isFocused && searchResults.length > 0 && (
                        <div
                            ref={searchResultRef}
                            className={`tac-search-results ${isFocused ? 'active' : ''}`}
                            style={{ width: inputWidth, margin: '0 auto' }}
                        >
                            {searchResults.map((article, index) => (
                                <div
                                    key={index}
                                    className="tac-search-result-item"
                                    onMouseDown={() => handleArticleClick(article.id)} // Use onMouseDown to handle click before blur
                                    style={{ cursor: 'pointer' }}
                                >
                                    {article.title}
                                </div>
                            ))}
                        </div>
                    )
                )}
            </div>
        </form>
    );
}

export default SearchForm;
