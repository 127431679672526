import { useEffect, useRef, useState } from "react";
import '../Notification/NotificationStyle.css'
import userService from '../../../services/userService'
export function Notification() {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState(null)
    const ref = useRef();
    const notifications = 5
    const notificationsDemo = [
        { id: 1, title: "Theo dõi", content: "John Doe vừa theo dõi bạn.", time: "5 phút trước", type: "friend_following", link: '#' },
        { id: 2, title: "Bình luận mới", content: "Jane Smith đã bình luận về bài viết của bạn.", time: "10 phút trước", type: "comment", link: '#' },
        { id: 3, title: "Thích bài viết của bạn", content: "Alex Johnson thích bài viết của bạn.", time: "30 phút trước", type: "like", link: '#' },
        { id: 4, title: "Mời tham gia sự kiện", content: "Bạn được mời tham gia sự kiện 'Lập trình React cơ bản'.", time: "1 giờ trước", type: "event", link: '#' },
        { id: 5, title: "Cập nhật mới", content: "Một bản cập nhật mới cho ứng dụng.", time: "2 giờ trước", type: "update", link: '#' }
    ];
    useEffect(() => {
        var currentUser = userService.getCurrentUserFromLocalStorge()
        if (currentUser) {
            setUser(currentUser)
        }
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [])
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen]);

    return (
        user ? (
            <>
                <div className="position-relative me-4 d-none d-md-block" ref={ref}>
                    <i className="bi bi-bell-fill tac-notification-bell" onClick={() => setIsOpen(!isOpen)}></i>
                    {notifications > 0 && (
                        <span className="badge rounded-circle bg-danger" style={{ position: 'absolute', right: -4, fontSize: '10px' }}>
                            {notifications}
                        </span>
                    )}
                    <div className={`tac-notification-dropdown ${isOpen ? 'active' : ''}`}>
                        {notificationsDemo.map((notification, index) => (
                            <a key={index} className="tac-notification-item" href={notification.link}>
                                <p>
                                    <b className="tac-title-notification">{notification.title}</b> <br /> {notification.content}<br /><span className="tac-time-notification">{notification.time}</span></p></a>
                        ))}
                    </div>
                </div>
                <div className="position-relative me-4 d-block d-md-none" ref={ref}>
                    <i className="bi bi-bell-fill tac-notification-bell" onClick={() => setIsModalOpen(!isOpen)}></i>
                    {notifications > 0 && (
                        <span className="badge rounded-circle bg-danger" style={{ position: 'absolute', right: -4, fontSize: '10px' }}>
                            {notifications}
                        </span>
                    )}
                    <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Thông báo <i className="bi bi-bell-fill"></i></h5>
                                    <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="list-group">
                                        {notificationsDemo.map((notification, index) =>
                                        (
                                            <a href="#" key={index} className="list-group-item list-group-item-action" aria-current="true">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{notification.title}</h5>
                                                    <small>{notification.time}</small>
                                                </div>
                                                <p className="mb-1">{notification.content}</p>
                                                <small>{notification.type}</small>
                                            </a>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <></>
        )

    );
}