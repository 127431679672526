const routePaths = {
  home: '/',
  postCreate: '/post-create',
  login: '/login',
  register: '/register',
  profile: '/profile',
  gallery: '/gallery',
  postDetail: '/post/:id',
  postsList: '/posts',
  editProfile: '/edit-profile',
  searchPost: '/search',
  topic: '/topic',
  setting: '/setting',
  otpVerification: '/otp-verification',
  myDevice: '/my-device',
  discovery: '/discovery'
};

export default routePaths;
