import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NavBot from '../Header/NavBot/NavBot';
import { Helmet } from 'react-helmet';

const Layout = ({ children, hasNavBot, headerProps, meta }) => {
    return (
        <>
            <Helmet>
                <title>{meta.title || "Default Title"}</title>
                <meta name="description" content={meta.description || "Default description"} />
                <meta name="keywords" content={meta.keywords || "default, keywords"} />
            </Helmet>
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
                <ToastContainer position="top-right" />
                <Header {...headerProps} />
                {hasNavBot && <NavBot />}
                <main className='mb-3'>
                    {children}
                </main>
                <Footer />
            </div>
        </>
    );
}

export default Layout;
