import React, { useState, useEffect } from 'react';
import './NavBot.css';
import topicService from '../../../services/topicService';
import routePaths from '../../../pages/routePaths';

function NavBot() {
  const [topics, setTopics] = useState(null);

  useEffect(() => {
    const getTopic = async () => {
      var response = await topicService.getTopics(1, 20);
      if (response.isSuccess) {
        setTopics(response.response.result);
      } else {
        console.log('Failed to fetch topics:', response);
      }
    };
    getTopic();
  }, []);

  return (
    <div className="navbot-container sticky-top">
      <a href={routePaths.home} className="nav-link">Trang chủ</a>
      <a href={routePaths.discovery} className="nav-link">Khám phá</a>
      {
        topics ? (
          topics.map((topic, index) => (
            <a key={index} href="#" className='nav-link'>{topic.name}</a>
          ))
        ) : (
          <a href="#home" className="nav-link">Home</a>
        )
      }
    </div>
  );
}

export default NavBot;
