// src/services/authService.js
import axios from 'axios';
import responseHandler from '../utils/responseHandler';
import userService from './userService';
import { API_URL } from '../config';

const register = async (userData) => {
  return await responseHandler(
    axios.post(`${API_URL}/auth/register`, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );
};

const checkTokenValid = async (userData) => {
  return await responseHandler(
    axios.post(`${API_URL}/auth/validate-token`, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );
};

const login = async (userData) => {
  try {
    const response = await responseHandler(
      axios.post(`${API_URL}/auth/login`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    );
    if (response.isSuccess && response.response.accessToken) {
      localStorage.setItem('token', JSON.stringify(response.response.token));
      await userService.setUser()
    }
    return response;
  } catch {
    return null
  }
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userInformation');
};

const getCurrentTokenStr = () => {
  const userStr = localStorage.getItem('token');
  if (userStr) return userStr
  return null;
};
const sendOtp = async (email) => {
  return await responseHandler(
    axios.post(`${API_URL}/auth/send-otp`, { email: email }, {
      headers: { 'Content-Type': 'application/json' },
    })
  );
};

const verificationOtp = async (userData) => {
  return await responseHandler(
    axios.post(`${API_URL}/auth/verification-otp`, userData, {
      headers: { 'Content-Type': 'application/json' },
    })
  );
};

const checkFieldValid = async (userData) => {
  return await responseHandler(
    axios.post(`${API_URL}/auth/check-field-valid`, userData, {
      headers: { 'Content-Type': 'application/json' },
    })
  );
};

export default {
  register,
  login,
  logout,
  getCurrentTokenStr,
  checkTokenValid,
  sendOtp,
  verificationOtp,
  checkFieldValid,
};