import axios from 'axios';
import responseHandler from '../utils/responseHandler';
import authService from './authService';
import { API_URL } from '../config';

export const getPosts = async ({ Keyword, pageNumber = 1, pageSize = 10 }) => {
  const token = authService.getCurrentTokenStr();
  const params = new URLSearchParams({ pageNumber, pageSize });

  if (Keyword) {
    params.append('Keyword', Keyword);
  }

  return await responseHandler(
    axios.get(`${API_URL}/posts?${params.toString()}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  );
};
export const getPostsByUserId = async (userId, pageNumber, pageSize) => {
  const token = authService.getCurrentTokenStr();
  const params = new URLSearchParams({ userId, pageNumber, pageSize });

  return await responseHandler(
    axios.get(`${API_URL}/posts?${params.toString()}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  );
}
export const createPost = async (title, content, languageId, fileThumbnail, attachmentIds) => {
  try {
    const token = authService.getCurrentTokenStr();
    const formData = new FormData();

    formData.append('Title', title);
    formData.append('Content', content);
    formData.append('LanguageId', languageId);
    formData.append('ShowMode', 'public');
    formData.append('CreateDate', new Date().toISOString());
    if (attachmentIds.length > 0) {
      formData.append('AttachmentIds', attachmentIds)
    }
    if (fileThumbnail) {
      formData.append('FileThumbnail', fileThumbnail);
    }

    return await responseHandler(
      axios.post(`${API_URL}/posts`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getPostById = async (postId) => {
  const token = authService.getCurrentTokenStr();
  return await responseHandler(
    axios.get(`${API_URL}/posts/${postId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  );
};

export default {
  createPost,
  getPosts,
  getPostById,
  getPostsByUserId
};