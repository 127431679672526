import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function PostsImport() {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null); // Post được chọn để hiển thị content
    const [error, setError] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const parsedPosts = parseExcelData(json);
            if (parsedPosts.error) {
                setError(parsedPosts.error);
            } else {
                setPosts(parsedPosts);
                setError(null);
            }
        };

        reader.onerror = () => {
            setError("Failed to read file.");
        };

        reader.readAsArrayBuffer(file);
    };

    const parseExcelData = (data) => {
        try {
            const headers = data[0];
            const rows = data.slice(1);
            return rows.map((row) => {
                const post = {};
                headers.forEach((header, index) => {
                    post[header] = row[index];
                });
                return post;
            });
        } catch (error) {
            return { error: "Invalid file format." };
        }
    };

    const handleShowContent = (post) => {
        setSelectedPost(post);
    };

    const handleCloseContentPopup = () => {
        setSelectedPost(null);
    };

    const handleSubmit = () => {
        console.log(posts);
        // Gửi dữ liệu bài viết lên server để lưu trữ
        // Bạn có thể sử dụng fetch hoặc axios để gửi dữ liệu này đến API backend
    };

    return (
        <div className="container mt-5">
            <div className="tac-section">
                <div className="mb-3">
                    <input type="file" className="form-control" accept=".xlsx, .xls" onChange={handleFileUpload} />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {posts.length > 0 && (
                    <>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    {Object.keys(posts[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {posts.map((post, index) => (
                                    <tr key={index}>
                                        {Object.keys(post).map((key) => (
                                            <td key={key}>
                                                {key === 'Content' ? (
                                                    <button className="btn btn-primary btn-sm" onClick={() => handleShowContent(post)}>View Content</button>
                                                ) : key === 'ThumbImage' ? (
                                                    <input type="file" className="form-control form-control-sm" accept="image/*" />
                                                ) : (
                                                    post[key]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button className="btn btn-success mt-3" onClick={handleSubmit}>Submit Posts</button>
                    </>
                )}

                {selectedPost && (
                    <div className="modal fade show" style={{ display: 'block' }} role="dialog">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Content for Post: {selectedPost.Title}</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseContentPopup}></button>
                                </div>
                                <div className="modal-body">
                                    <p dangerouslySetInnerHTML={{__html:selectedPost.Content}}></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseContentPopup}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PostsImport;
