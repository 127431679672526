import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Avatar.css';
import routePaths from '../../../pages/routePaths'
import authService from '../../../services/authService';
import userService from '../../../services/userService';

const defaultAvatarUrl = 'https://ttac.vn/images/default_avatar.png'
function TopAvatar() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [user, setUser] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    var currentUser = userService.getCurrentUserFromLocalStorge();
    if (currentUser) {
      setUser(currentUser)
    }
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const logout = () => {
    authService.logout();
  };

  return (
    <div ref={dropdownRef} className={`tac-avatar-container ${dropdownVisible ? 'active' : ''}`}>
      {user ? (
        <>
          <span className='tac-name-user'>{user.firstName}</span>
          <img
            src={user.avatarUrl || defaultAvatarUrl}
            alt="Avatar"
            className="rounded-circle tac-avatar-image ms-2"
            width={40}
            onClick={toggleDropdown}
          />
          {dropdownVisible && (
            <div className="tac-dropdown-menu">
              <Link className='tac-dropdown-url' to={`${routePaths.profile}?user=${user.id}`}>
                <div className="tac-dropdown-item">Hồ sơ của tôi</div>
              </Link>
              {/* <Link className='tac-dropdown-url' to={routePaths.setting}>
                <div className="tac-dropdown-item">Cài đặt</div>
              </Link> */}
              <Link className='tac-dropdown-url' to={routePaths.login} onClick={logout}>
                <div className="tac-dropdown-item">Đăng xuất</div>
              </Link>
            </div>
          )}
        </>
      ) : (
        <Link to={routePaths.login} className='tac-link'>
          Đăng nhập
        </Link>
      )}
    </div>
  );
}

export default TopAvatar;
