import authService from "../services/authService";
import { toast } from "react-toastify";
// src/utils/responseHandler.js
const responseHandler = async (promise) => {
  try {
    const response = await promise;
    return {
      isSuccess: response.data.isSuccess,
      response: response.data,
      headers: response.headers
    };
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      authService.logout();
      toast.error('Phiên đăng nhập đã hết hạn, vui lòng thử lại')
      return {
        isSuccess: false,
        response: null
      }
    }
    return {
      isSuccess: false,
      response: error,
    };
  }
};

export default responseHandler;
