import React from 'react';
import Title from "./Title/Title";
import TopAvatar from "./Avatar/Avatar";
import Logo from "./Logo/Logo";
import { Notification } from './Notification/Notification';
import { SearchForm } from './SearchForm/SearchForm';
import './Header.css';

function Header({ title, hasSearchForm, notStickyTop }) {
    return (
        <nav className={`container-fluid tac-background-white header-navbar ${notStickyTop ? '' : 'sticky-top'}`}>
            <div className='container p-2'>
                <div className={`row align-items-center`}>
                    <div className='col-auto text-center'>
                        <Logo />
                    </div>
                    {hasSearchForm ? (
                        <div className='col d-none d-md-block'>
                            <SearchForm />
                        </div>
                    ) : (
                        <div className='col'>
                            <Title title={title} />
                        </div>
                    )}
                    <div className='col-auto ms-auto'>
                        <div className='d-flex align-items-center'>
                            <Notification />
                            <TopAvatar />
                        </div>
                    </div>
                    <div className={`col-12 d-block d-md-none mt-2 border-top pt-2`}>
                        <SearchForm />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
