import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png'
import './LogoStyle.css'
import routePaths from '../../../pages/routePaths'
function Logo(){
    return (
        <Link to={routePaths.home}><img className='tac-logo' src={logo} alt="Logo" height={50} width={65}/></Link>
    );
}


export default Logo