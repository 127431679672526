import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import routePaths from './routePaths';
import userService from '../services/userService';

const ProtectedRoute = ({ element, isAuthorization }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    const checkAuthorization = async () => {
      const user = await userService.getCurrentUserFromLocalStorge();
      if (user && user.email == 'damgiacuong2102@gmail.com') {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };
    checkAuthorization();
  }, []);

  if (!isAuthorization) {
    return element;
  }

  if (isAuthorized === null) {
    return (
      <div className="text-center">
        <div className="spinner-border spinner-border-sm tac-normal-text" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return isAuthorized ? element : <Navigate to={routePaths.login} />;
};

export default ProtectedRoute;
